<template>
  <div id="app">
    <div
      class="slider"
      ref="slider"
      @click="handleClick"
      :style="sliderBackground"
    >
      <img
        v-for="(image, index) in images"
        :key="index"
        :src="image.lowQualitySrc"
        class="slide"
        @load="loadHighQualityImage(index)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      images: [
        {
          lowQualitySrc: "images/low/0_1.png",
          highQualitySrc: "images/0_1.png",
          section: 0,
        },
        {
          lowQualitySrc: "images/low/0_2.png",
          highQualitySrc: "images/0_2.png",
          section: 0,
        },
        {
          lowQualitySrc: "images/low/0_3.png",
          highQualitySrc: "images/0_3.png",
          section: 0,
        },
        {
          lowQualitySrc: "images/low/0_4.png",
          highQualitySrc: "images/0_4.png",
          section: 0,
        },
        {
          lowQualitySrc: "images/low/0_5.png",
          highQualitySrc: "images/0_5.png",
          section: 0,
        },
        {
          lowQualitySrc: "images/low/0_6.png",
          highQualitySrc: "images/0_6.png",
          section: 0,
        },
        {
          lowQualitySrc: "images/low/1_01.png",
          highQualitySrc: "images/1_01.png",
          section: 1,
        },
        {
          lowQualitySrc: "images/low/1_02.png",
          highQualitySrc: "images/1_02.png",
          section: 1,
        },
        {
          lowQualitySrc: "images/low/1_03.png",
          highQualitySrc: "images/1_03.png",
          section: 1,
        },
        {
          lowQualitySrc: "images/low/1_04.png",
          highQualitySrc: "images/1_04.png",
          section: 1,
        },
        {
          lowQualitySrc: "images/low/1_05.png",
          highQualitySrc: "images/1_05.png",
          section: 1,
        },
        {
          lowQualitySrc: "images/low/1_06.png",
          highQualitySrc: "images/1_06.png",
          section: 1,
        },
        {
          lowQualitySrc: "images/low/2_07.png",
          highQualitySrc: "images/2_07.png",
          section: 2,
        },
        {
          lowQualitySrc: "images/low/2_08.png",
          highQualitySrc: "images/2_08.png",
          section: 2,
        },
        {
          lowQualitySrc: "images/low/2_09.png",
          highQualitySrc: "images/2_09.png",
          section: 2,
        },
        {
          lowQualitySrc: "images/low/2_10.png",
          highQualitySrc: "images/2_10.png",
          section: 2,
        },
        {
          lowQualitySrc: "images/low/2_11.png",
          highQualitySrc: "images/2_11.png",
          section: 2,
        },
        {
          lowQualitySrc: "images/low/2_12.png",
          highQualitySrc: "images/2_12.png",
          section: 2,
        },
        {
          lowQualitySrc: "images/low/2_13.png",
          highQualitySrc: "images/2_13.png",
          section: 2,
        },
        {
          lowQualitySrc: "images/low/2_14.png",
          highQualitySrc: "images/2_14.png",
          section: 2,
        },
        {
          lowQualitySrc: "images/low/2_15.png",
          highQualitySrc: "images/2_15.png",
          section: 2,
        },
        {
          lowQualitySrc: "images/low/2_16.png",
          highQualitySrc: "images/2_16.png",
          section: 2,
        },
        {
          lowQualitySrc: "images/low/2_17.png",
          highQualitySrc: "images/2_17.png",
          section: 2,
        },
        {
          lowQualitySrc: "images/low/2_18.png",
          highQualitySrc: "images/2_18.png",
          section: 2,
        },
        {
          lowQualitySrc: "images/low/3_19.png",
          highQualitySrc: "images/3_19.png",
          section: 3,
        },
        {
          lowQualitySrc: "images/low/3_20.png",
          highQualitySrc: "images/3_20.png",
          section: 3,
        },
        {
          lowQualitySrc: "images/low/3_21.png",
          highQualitySrc: "images/3_21.png",
          section: 3,
        },
        {
          lowQualitySrc: "images/low/3_22.png",
          highQualitySrc: "images/3_22.png",
          section: 3,
        },
        {
          lowQualitySrc: "images/low/4_23.png",
          highQualitySrc: "images/4_23.png",
          section: 4,
        },
        {
          lowQualitySrc: "images/low/4_24.png",
          highQualitySrc: "images/4_24.png",
          section: 4,
        },
        {
          lowQualitySrc: "images/low/4_25.png",
          highQualitySrc: "images/4_25.png",
          section: 4,
        },
        {
          lowQualitySrc: "images/low/4_26.png",
          highQualitySrc: "images/4_26.png",
          section: 4,
        },
        {
          lowQualitySrc: "images/low/5_27.png",
          highQualitySrc: "images/5_27.png",
          section: 5,
        },
        {
          lowQualitySrc: "images/low/5_28.png",
          highQualitySrc: "images/5_28.png",
          section: 5,
        },
        {
          lowQualitySrc: "images/low/5_29.png",
          highQualitySrc: "images/5_29.png",
          section: 5,
        },
        {
          lowQualitySrc: "images/low/5_30.png",
          highQualitySrc: "images/5_30.png",
          section: 5,
        },
        {
          lowQualitySrc: "images/low/5_31.png",
          highQualitySrc: "images/5_31.png",
          section: 5,
        },
        {
          lowQualitySrc: "images/low/5_32.png",
          highQualitySrc: "images/5_32.png",
          section: 5,
        },
        {
          lowQualitySrc: "images/low/5_33.png",
          highQualitySrc: "images/5_33.png",
          section: 5,
        },
        {
          lowQualitySrc: "images/low/5_34.png",
          highQualitySrc: "images/5_34.png",
          section: 5,
        },
        {
          lowQualitySrc: "images/low/5_35.png",
          highQualitySrc: "images/5_35.png",
          section: 5,
        },
        {
          lowQualitySrc: "images/low/6_36.png",
          highQualitySrc: "images/6_36.png",
          section: 6,
        },
        {
          lowQualitySrc: "images/low/6_37.png",
          highQualitySrc: "images/6_37.png",
          section: 6,
        },
        {
          lowQualitySrc: "images/low/6_38.png",
          highQualitySrc: "images/6_38.png",
          section: 6,
        },
        {
          lowQualitySrc: "images/low/6_39.png",
          highQualitySrc: "images/6_39.png",
          section: 6,
        },
        {
          lowQualitySrc: "images/low/6_40.png",
          highQualitySrc: "images/6_40.png",
          section: 6,
        },
        {
          lowQualitySrc: "images/low/6_41.png",
          highQualitySrc: "images/6_41.png",
          section: 6,
        },
        {
          lowQualitySrc: "images/low/6_42.png",
          highQualitySrc: "images/6_42.png",
          section: 6,
        },
        {
          lowQualitySrc: "images/low/7_43.png",
          highQualitySrc: "images/7_43.png",
          section: 7,
        },
        {
          lowQualitySrc: "images/low/7_44.png",
          highQualitySrc: "images/7_44.png",
          section: 7,
        },
        {
          lowQualitySrc: "images/low/7_45.png",
          highQualitySrc: "images/7_45.png",
          section: 7,
        },
        {
          lowQualitySrc: "images/low/7_46.png",
          highQualitySrc: "images/7_46.png",
          section: 7,
        },
        {
          lowQualitySrc: "images/low/7_47.png",
          highQualitySrc: "images/7_47.png",
          section: 7,
        },
      ],
      currentIndex: 0,
      sectionColors: {
        0: "rgba(32, 31, 29, 0.5)",
        1: "rgba(9, 118, 52, 0.5)",
        2: "rgba(219, 10, 114, 0.5)",
        3: "rgba(149, 36, 45, 0.5)",
        4: "rgba(24, 214, 178, 0.5)",
        5: "rgba(245, 178, 53, 0.5)",
        6: "rgba(141, 88, 135, 0.5)",
        7: "rgba(34, 185, 211, 0.5)",
      },
    };
  },
  computed: {
    sliderBackground() {
      const sectionColor =
        this.sectionColors[this.images[this.currentIndex].section];
      const nextSectionColor =
        this.currentIndex < this.images.length - 1
          ? this.sectionColors[this.images[this.currentIndex + 1].section]
          : sectionColor;

      return {
        background: `linear-gradient(to right, ${sectionColor}, rgba(255, 255, 255, 0) 50%, ${nextSectionColor})`,
      };
    },
  },
  methods: {
    handleClick(event) {
      const slider = this.$refs.slider;
      const clickX = event.clientX;
      const sliderWidth = slider.offsetWidth;

      const leftBoundary = sliderWidth * 0.25;
      const rightBoundary = sliderWidth * 0.75;

      if (clickX < leftBoundary) {
        this.currentIndex = Math.max(0, this.currentIndex - 1);
      } else if (clickX > rightBoundary) {
        this.currentIndex = Math.min(
          this.images.length - 1,
          this.currentIndex + 1
        );
      }

      slider.scrollTo({
        left: this.currentIndex * sliderWidth,
        behavior: "smooth",
      });
    },
    loadHighQualityImage(index) {
      const img = new Image();
      img.onload = () => {
        this.images[index].lowQualitySrc = this.images[index].highQualitySrc;
      };
      img.src = this.images[index].highQualitySrc;
    },
  },
};
</script>

<style>
.slider {
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  position: relative;
  cursor: pointer;
}

.slide {
  flex: 0 0 auto;
  width: 100vw;
  height: 100vh;
  object-fit: contain;
}
</style>
